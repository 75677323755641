.blogItemContainer {
  background-color: white;
  padding: 20px;
  position: relative;
  border-radius: 7px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 11px;
  margin: 10px;
}

.blogItemContainer h2 {
  background: linear-gradient(60deg, rgb(58, 108, 183) 0%, rgb(81, 136, 218) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blogItemContainer p {
  font-size: 0.8em;
  color: rgb(71, 71, 71);
  font-style: italic;
}

.blogItemContainer:hover {
  padding: 20px 30px;
}

@media (max-width: 768px) {
  .blogItemContainer {
    width: 100%;
  }
}

