/* Global styles across whole APP */

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, rgb(58, 102, 183) 0%, rgba(0, 172, 193, 1) 100%);
  color: white;
  overflow: hidden;
}

.inner-header {
  min-height: 70vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  margin-top: 80px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Slideshow nav icons */
.default-nav {
  margin: 20px;
}

@keyframes opacityChange {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .inner-header {
    min-height: 70vh;
    margin-top: 60px;
  }

  
}
