.wrapper {
  width: 90%;
  margin: auto;  
  flex-direction: row;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
}

.textWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1em;
}

.list-1 {
  font-size: 2em;
}

.list-2 {
  font-size: 1.5em;
}

.list-3 {
  font-size: 1.25em;
}

.matrosWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    gap: 20px;
    width: 90%;
    flex-direction: column;
  }

  .textWrapper {
    width: 100%;
  }
  .list-1 {
    font-size: 1.5em;
  }
  .list-2 {
    font-size: 1.25em;
  }
  .list-3 {
    font-size: 1em;
  }
}