.titleWrapper {
  display: flex;
  align-items: center;
}

.titleWrapper span {
  font-size: 3em;
  transition: 0.2s ease-in-out;
  padding-right: 20px;
  cursor: pointer;
}

.titleWrapper span:hover {
  padding-right: 25px;
}

.blogPostContainer .title {
  font-size: 2em;
  letter-spacing: 1ox;
}

.blogPostContainer .date {
  color: rgb(190, 190, 190);
  font-style: italic;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.blogPostContainer img {
  max-width: 100%;
  object-fit: cover;
}

.bloggSlideshow {
  width: 50%;
  margin-top: 40px;
}


@media (max-width: 768px) {
  .blogPostContainer {
    width: 90%;
  }

  .bloggSlideshow {
    width: 100%;
  }
  
}
