.container {
  width: 100%;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;

}

.slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 500px;
  transition: .3s ease-in-out;
}

.slide span {
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  color: white;
  background: rgba(141, 174, 224, 0.5);
  text-align: center;
}



@media (max-width: 768px) {
  .slide > div {
    height: 250px;
  }
}
