.screen-container {
  width: 80%;
  margin-top: 50px;
  animation: opacityChange 1.2s ease-in-out;
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .screen-container {
    width: 90%;
  }
}
