.capsContainer h2 {
  margin-bottom: 5px;
  margin-top: 20px;
}

.capsContainer p {
  margin-top: 5px;
}

.capsContainer h1 {
  font-size: 3em;
}

.capsContainer hr {
  margin-bottom: 10px;
}

.capsContainer ul {
  margin-left: 20px;
}

.capsContainer img {
  max-width: 300px;
  border-radius: 7px;
  margin-top: 50px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .capsContainer img {
    max-width: 300px;
    border-radius: 7px;
    display: flex;
    margin: auto;
    margin-top: 50px;
    object-fit: cover;
  }
  .capsContainer {
    width: 90%;
  }
}
