.spelTextContainer h2 {
  margin-bottom: 10px;
}

.gameCardWrapper {
  width: 100%;
  justify-content: center;
  transition: 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .spelContainer {
    margin-top: 60px;
    width: 90%;
  }
  .spelTextContainer h2 {
    font-size: 1.2em;
  }
  .spelTextContainer p {
    font-size: 0.8em;
  }
}
