.gameCardContainer {
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  margin-top: 30px;
  border-radius: 10px;
  color: rgb(98, 98, 98);
  text-align: left;
  cursor: pointer;
  justify-content: space-evenly;
}

.gameCardContainer section {
  padding: 20px;
  display: flex;
  align-items: center;
}

.gameCardContainer h2 {
  transition: 0.2s ease-in-out;
  letter-spacing: 1px;
  font-size: 2em;
  background: linear-gradient(60deg, rgb(58, 108, 183) 0%, rgba(0, 172, 193, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gameCardFadeIn 0.4s ease-in-out;
  margin-bottom: 10px;
}
.imageWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.gameCardContainer img {
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 7px;
  border-radius: 5px;
  animation: gameCardFadeIn 0.4s ease-in-out;
}

.ruleContainer ul {
  margin-left: 20px;
}

.ruleContainer h4 {
  animation: gameCardFadeIn 0.4s ease-in-out;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.ruleContainer p,
.ruleContainer li {
  animation: gameCardFadeIn 0.4s ease-in-out;
  font-size: 0.9em;
}

.gameCardContainer:hover h2 {
  font-size: 2.5em;
}

@media (max-width: 768px) {
  .gameCardContainer section {
    width: 100%;
    text-align: left;
    padding: 10px;
    display: block;
    padding: 0;
  }
  .infoContainer {
    padding: 10px;
  }
  .gameCardContainer section img {
    margin: 10px auto;
    max-width: 200px;
    max-height: 200px;
  }
  .ruleContainer h4 {
    font-size: 1.1em;
  }
  .ruleContainer p {
    font-size: 0.8em;
  }
}

@keyframes gameCardFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
