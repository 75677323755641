.blogTitle {
  margin-bottom: 20px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.blogItemWrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
