.aboutContainer h2 {
  margin-bottom: 10px;
}

.aboutContainer img {
  width: 200px;
  margin-top: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.medlemar {
  background-color: white;
  border-radius: 5px;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5x 10px;
  color: rgb(83, 83, 83);
  padding: 20px 20px 10px 20px;
}

.medlemar h2 {
  background: linear-gradient(60deg, rgb(58, 108, 183) 0%, rgba(0, 172, 193, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.medlemar p {
  margin-bottom: 20px;
}