.mobileNavWrapper {
  font-family: "Poppins", sans-serif;
  animation: fadeIn 0.6s ease-in-out;
  transition: 0.2s ease-in-out;
  position: relative;
}

.topBarMobile .scrollStyle {
  background: #333;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
}

.topBarMobile {
  align-items: center;
  z-index: 2;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(235, 235, 235);
  background-color: white;
}

.hamburger-react {
  margin-right: 20px;
}

.listWrapperMobile {
  width: 100%;
  display: block;
  transition: 0.4s ease-in-out;
  z-index: 1;
  top: -300px;
  position: fixed;
}

.linkMobile {
  text-align: center;
  color: #333;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid rgb(235, 235, 235);
  background-color: white;
  text-decoration: none;
}

.listWrapperMobile .active {
  border-bottom: 1px solid rgba(0, 172, 193, 1);
}

.listWrapperMobile .active:nth-last-child() {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
}
