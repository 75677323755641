@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  margin:0;
}

body::-webkit-scrollbar {
  display: none;
}

.textParser img {
  height: 300px;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  border-radius: 5px;
}
