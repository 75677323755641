.customNav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  animation: fadeIn 0.6s ease-in-out;
  z-index: 10;
  transition: 0.2s ease-in-out;
  overflow: hidden;
}

.customNav div {
  display: flex;
  align-items: center;
}

.topBar h3 {
  font-size: 1.8em;
  color: white;
  letter-spacing: 1px;
}

.navbar {
  margin-right: 30px;
}

.logolink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.link {
  font-size: 1.1em;
  color: white;
  padding: 0 20px;
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  text-decoration: none;
  letter-spacing: 1px;
  height: calc(100% - 5px);
  align-items: center;
  display: flex;
  transition: .2s ease-in-out;
}

.link:hover {
  border-bottom: 5px solid white;
}

.scrollActive {
  border-bottom: 5px solid rgba(0, 172, 193, 1);
}
.malvacomNav .active {
  border-bottom: 5px solid rgb(255, 253, 253);
}

.topBar img {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 20px;
}

.scrollStyle {
  background: #333;
  width: 30px;
  height: 30px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  border-radius: 50%;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
